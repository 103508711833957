<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.upkeepContracts')"
    id="upkeepContracts"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button
          v-has="'编辑保养合同'"
          class="fr"
          type="text"
          @click="setIsEdit(false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onEditSubmit"
          @resetForm="setIsEdit(true)"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.receiptInfo") }}</span>
        <el-button
          v-has="'导出财务数据'"
          class="fr"
          type="text"
          @click="exportFinance"
        >
          {{ $t("i18n.export") }}
        </el-button>
        <el-button
          v-has="'添加收款记录'"
          class="fr"
          type="text"
          @click="dialogOnEvent('newReceiptVisible', true)"
        >
          {{ $t("i18n.newReceiptInfo") }}
        </el-button>
        <el-button
          v-has="'添加开票记录'"
          class="fr"
          type="text"
          @click="dialogOnEvent('newBillingVisible', true)"
        >
          {{ $t("i18n.newBillingRecord") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getBillData"
          :tableData="receiptData"
          :options="receiptOptions"
          :columns="receiptColumns"
          :operates="receiptOperates"
          :total="receiptTotal"
          :height="300"
          @handleSelectionChange="
            (val) => {
              handleSelectionChange(val, 'selectEleData');
            }
          "
        >
        </jl-table>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.statusInfo") }}</span>
      </template>
      <div class="text item">
        <jl-form :columns="statusColumns"></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
        <el-button class="fr" type="text" @click="newElevatorVisible = true">
          {{ $t("i18n.addElevator") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          :tableData="elevatorData"
          :options="receiptDetailOptions"
          :columns="elevatorColumns"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.renewRecord") }}</span>
      </template>
      <div class="text item">
        <jl-table
          @init="getRenewList"
          :tableData="renewData"
          :options="receiptDetailOptions"
          :columns="renewColumns"
          :operates="receiptOperates"
          :total="renewTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-dialog
      :title="$t('i18n.receiptInfo')"
      v-model="dialogReceiptVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('i18n.billingInformation')" name="1">
          <jl-table
            @init="getBillList"
            :tableData="billDetailData"
            :options="receiptDetailOptions"
            :columns="billDetailColumns"
            :operates="billDetailOperates"
            :total="billDetailTotal"
          ></jl-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('i18n.receiptInfo')" name="2">
          <jl-table
            @init="getReceiptList"
            :tableData="receiptDetailData"
            :options="receiptDetailOptions"
            :columns="receiptDetailColumns"
            :operates="receiptDetailOperates"
            :total="receiptDetailTotal"
          ></jl-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.newBillingRecord')"
      v-model="newBillingVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="billDetailColumns"
        @onSubmit="addBilling"
        @resetForm="dialogOnEvent('newBillingVisible', false)"
      ></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.newReceiptInfo')"
      v-model="newReceiptVisible"
      :close-on-click-modal="false"
    >
      <jl-form
        :columns="receiptDetailColumns"
        @onSubmit="addReceipt"
        @resetForm="dialogOnEvent('newReceiptVisible', false)"
      ></jl-form>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="receiptOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "UpkeepContractViewupdate",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        pageNumber: 1,
        pageSize: 10,
      },
      activeName: "1",
      dialogReceiptVisible: false,
      basicColumns: [
        {
          prop: "number",
          label: "maintenanceContractNo",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "property",
          label: "contractNature",
          type: "select",
          readonly: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "property",
        },
        {
          prop: "status",
          label: "contractStatus",
          type: "select",
          readonly: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "contractStatus",
        },
        {
          prop: "source",
          label: "contractSource",
          type: "select",
          readonly: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "source",
        },
        {
          prop: "ownerFullname",
          label: "ownerName",
          type: "input",
          readonly: true,
        },
        {
          prop: "ownerShortname",
          label: "ownerNickname",
          type: "input",
          readonly: true,
        },
        {
          prop: "firstPartyName",
          label: "partyA",
          type: "input",
          readonly: true,
        },
        {
          prop: "secondPartyName",
          label: "partyB",
          type: "input",
          readonly: true,
        },
        {
          prop: "beginDate",
          label: "effectiveDate",
          type: "date",
          readonly: true,
          change: (val) => {
            setValueByRank(val, "beginDate");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "endDate",
          label: "expiryDate",
          type: "date",
          readonly: true,
          change: (val) => {
            setValueByRank(val, "endDate");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "durationMonth",
          label: "contractDuration",
          type: "input",
          readonly: true,
        },
        {
          prop: "signDate",
          label: "signingDate",
          type: "date",
          readonly: true,
        },
        {
          prop: "duration",
          label: "contractPeriod",
          type: "input",
          readonly: true,
        },
        {
          prop: "elevatorCount",
          label: "numberOfElevator",
          type: "input",
          readonly: true,
        },
        {
          prop: "value",
          label: "contractAmount",
          type: "input",
          readonly: true,
        },
        {
          prop: "remindPhone",
          label: "smsNumber",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "maintainerName",
          label: "maintenancePerson",
          type: "select",
          readonly: true,
          props: { label: "name", value: "id" },
          data: [],
        },
        {
          prop: "maintainerPhone",
          label: "contractInformation",
          type: "input",
          readonly: true,
        },
        {
          prop: "isSubpackage",
          label: "subcontracting",
          type: "switch",
          text: { aText: "yes", aVal: "1", iText: "no", iVal: "0" },
          readonly: true,
        },
        {
          prop: "subpackageCompanyName",
          label: "subcontractCompany",
          type: "input",
          readonly: true,
        },
        {
          prop: "purchaseNo",
          label: "purchaseNo",
          type: "input",
          readonly: true,
        },
        {
          prop: "areaAttribute",
          label: "regionAttribute",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          readonly: true,
        },
        {
          prop: "manager",
          label: "executive",
          type: "remote",
          props: { label: "name", value: "id" },
          loading: false,
          readonly: true,
          data: [],
          remoteMethod: (val) => {
            remotePersonMethod(val, "basicColumns", "manager");
          },
        },
        {
          prop: "manuscript",
          label: "originalcontract",
          type: "upload",
          readonly: true,
          action: "contracts",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
        },
      ],
      statusColumns: [
        {
          prop: "remainDays",
          label: "countdownExpiryDate",
          type: "input",
          readonly: true,
        },
        {
          prop: "renewStatus",
          label: "renewalStatus",
          type: "input",
          readonly: true,
        },
        {
          prop: "allBillingValue",
          label: "invoiceAmount",
          type: "input",
          readonly: true,
        },
        {
          prop: "needCollectValue",
          label: "needInvoice",
          type: "input",
          readonly: true,
          format: (val) => {
            if (val > 0) {
              return val;
            } else {
              return `<span style="color: #f12828;">
                ${val} ${t("i18n.recordWrong")}
              </span>`;
            }
          },
        },
        {
          prop: "allCollectValue",
          label: "accountAmount",
          type: "input",
          readonly: true,
        },
        {
          prop: "receivableValue",
          label: "accountReceivable",
          type: "input",
          readonly: true,
        },
      ],
      receiptOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      receiptTotal: 0,
      receiptData: [],
      receiptColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            showReceiptDetail(row.id);
          },
        },
        {
          prop: "billingValue",
          label: "invoicedAmount",
          align: "center",
        },
        {
          prop: "payOrCollectionValue",
          label: "receivedAmount",
          align: "center",
        },
        {
          prop: "lastCheckDate",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "avgValue",
          label: "singleElevatorUpkeepCost",
          align: "center",
        },
      ],
      receiptOperates: {
        width: 200,
        fixed: "right",
        list: [],
      },
      billDetailTotal: 0,
      billDetailData: [],
      receiptDetailOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      billDetailOperates: {
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              deleteReceiptDetail(row, 10);
            },
          },
        ],
      },
      billDetailColumns: [
        {
          prop: "billno",
          label: "invoiceNo",
          align: "center",
          edit: true,
          method: (key, val, row) => {
            editReceiptDetail(key, val, row, "editBill");
          },
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "billingDate",
          label: "billingDate",
          align: "center",
          type: "date",
          width: 160,
          edit: true,
          method: (key, val, row) => {
            let value = proxy.$defined.format(val) + " 00:00:00";
            editReceiptDetail(key, value, row, "editBill");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "billValue",
          label: "invoiceAmount",
          align: "center",
          edit: true,
          method: (key, val, row) => {
            editReceiptDetail(key, val, row, "editBill");
          },
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "matters",
          label: "relatedIssues",
          align: "center",
          edit: true,
          method: (key, val, row) => {
            editReceiptDetail(key, val, row, "editBill");
          },
          type: "textarea",
        },
      ],
      receiptDetailData: [],
      receiptDetailTotal: 0,
      receiptDetailOperates: {
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              deleteReceiptDetail(row, 20);
            },
          },
        ],
      },
      receiptDetailColumns: [
        {
          prop: "billNo",
          label: "invoiceNo",
          align: "center",
          edit: true,
          method: (key, val, row) => {
            editReceiptDetail(key, val, row, "editCollect");
          },
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "collectingDate",
          label: "collectionDays",
          align: "center",
          type: "date",
          width: 160,
          edit: true,
          method: (key, val, row) => {
            let value = proxy.$defined.format(val) + " 00:00:00";
            editReceiptDetail(key, value, row, "editCollect");
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "collectingValue",
          label: "amountCollected",
          align: "center",
          edit: true,
          method: (key, val, row) => {
            editReceiptDetail(key, val, row, "editCollect");
          },
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      editId: "", // 存放当时操作id
      selectEleData: [], // 选中电梯数据
      selectNewData: [], // 选中未添加到合同的电梯
      newBillingVisible: false, // 新增开票记录弹窗
      newReceiptVisible: false, // 新增收款记录弹窗
      newElevatorVisible: false, // 新增电梯弹窗
      elevatorData: [],
      elevatorTotal: 0,
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorVarietyStr",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "manufacturerName",
          label: "manufacturingCompany",
          align: "center",
        },
        {
          prop: "lastCheckDate",
          label: "lastAnnualInspectionDate",
          align: "center",
        },
        {
          prop: "avgValue",
          label: "singleElevatorUpkeepCost",
          align: "center",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "delete",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              deleteElevator(row);
            },
          },
        ],
      },
      addEleData: [],
      addEleTotal: 0,
      addEleColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          search: true,
          type: "input",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewElevator();
            },
          },
        ],
      },
      renewData: [],
      renewTotal: 0,
      renewColumns: [
        {
          prop: "renewS",
          label: "renewalStatus",
          align: "center",
        },
        {
          prop: "number",
          label: "renewalNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/upkeepContract/upkeepContractViewupdate",
              query: { id: row.newId },
            });
          },
        },
        {
          prop: "renewDate",
          label: "operationTime",
          align: "center",
        },
        {
          prop: "loseReason",
          label: "loseReason",
          align: "center",
        },
      ],
    });

    const init = () => {
      let id = route.query.id;
      getContractInfo(id);
      getMainPerson();
      areaAttribute();
    };

    const getBillData = async (params) => {
      // 获取收款信息
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.contractBills(id, params);
      state.receiptData = data.records;
      state.receiptTotal = data.total;
    };

    const getElevatorList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getContractEle(id, params);
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const getRenewList = async (params) => {
      // 加载续签记录
      let id = route.query.id;
      const { data } = await proxy.$api.maintenance.getContractRenew(
        id,
        params
      );
      state.renewData = data.records;
      state.renewTotal = data.total;
    };

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      let id = route.query.id;
      params.eleNumber = params.number || "";
      params.projectName = params.projectName || "";
      const { data } = await proxy.$api.maintenance.getContractOtherEle(
        id,
        params
      );
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const getContractInfo = async (id) => {
      // 获取合同详细信息
      let { data } = await proxy.$api.maintenance.contractOne(id);
      setIsEdit(true, data.baseInfo);
      setStatusData(data.stateInfo);
    };

    const setStatusData = (data) => {
      // 状态信息赋值
      state.statusColumns.map((item) => {
        item.value = data[item.prop];
      });
    };

    const showReceiptDetail = (id) => {
      // 展示收款信息详情
      state.dialogReceiptVisible = true;
      state.editId = id;
    };

    const getBillList = async (params) => {
      // 开票信息
      let { data } = await proxy.$api.maintenance.elevatorBill(
        state.editId,
        route.query.id,
        params
      );
      data.records.map((item) => {
        item.billingDate = item.billingDate.split(" ")[0];
      });
      state.billDetailData = data.records;
      state.billDetailTotal = data.total;
    };

    const getReceiptList = async (params) => {
      // 收款信息
      let { data } = await proxy.$api.maintenance.elevatorCollect(
        state.editId,
        route.query.id,
        params
      );
      data.records.map((item) => {
        item.collectingDate = item.collectingDate.split(" ")[0];
      });
      state.receiptDetailData = data.records;
      state.receiptDetailTotal = data.total;
    };

    const deleteReceiptDetail = (row, type) => {
      // 删除收款记录
      let callback = () => {
        proxy.$api.maintenance.delBillOrCollect(row.id, type).then(() => {
          getBillList(state.paging);
          getReceiptList(state.paging);
          getBillData(state.paging);
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    const deleteElevator = (row) => {
      // 删除电梯
      let callback = () => {
        proxy.$api.maintenance
          .delContractEle(row.id, route.query.id)
          .then(() => {
            getElevatorList(state.paging);
            proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
          });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };

    const editReceiptDetail = async (key, val, row, func) => {
      // 编辑收款记录
      await proxy.$api.maintenance[func]({ [key]: val, id: row.id });
      getBillList(state.paging);
      getReceiptList(state.paging);
      getBillData(state.paging);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
    };

    const addBilling = async (row) => {
      // 新增开票记录
      row.upkeepContractId = route.query.id;
      row.elevatorId = checkSelectEle("selectEleData");
      row.payType = 30;
      row.billingDate = proxy.$defined.format(row.billingDate) + " 00:00:00";
      await proxy.$api.maintenance.addContractBills(route.query.id, row);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      state.newBillingVisible = false;
      getBillData(state.paging);
    };

    const addReceipt = async (row) => {
      // 新增收款记录
      row.upkeepContractId = route.query.id;
      row.elevatorId = checkSelectEle("selectEleData");
      row.payType = 20;
      row.collectingDate =
        proxy.$defined.format(row.collectingDate) + " 00:00:00";
      await proxy.$api.maintenance.addContractColl(route.query.id, row);
      proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
      state.newReceiptVisible = false;
      getBillData(state.paging);
    };

    const getMainPerson = async () => {
      // 维保人员
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.basicColumns.map((item) => {
        if (item.prop === "maintainerName") {
          item.data = data;
        }
      });
    };

    const areaAttribute = async () => {
      // 区域属性
      let { data } = await proxy.$api.maintenance.areaAttribute();
      state.basicColumns.map((item) => {
        if (item.prop === "areaAttribute") {
          item.data = data;
        }
      });
    };

    const remotePersonMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance.getManagerData(val).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };
    const setIsEdit = (flag, data) => {
      state.basicColumns.map((item) => {
        item.readonly =
          item.prop === "durationMonth" || item.prop === "duration"
            ? true
            : flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
            if (item.prop === "beginDate" || item.prop === "endDate") {
              state[item.prop] = data[item.prop];
            }
          }
        }
      });
    };

    const onEditSubmit = async (data) => {
      let dateArr = ["beginDate", "endDate", "signDate"];
      let form = setData(dateArr, data);
      await proxy.$api.maintenance.editContractOne(route.query.id, form);
      getContractInfo(route.query.id);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
    };

    const setData = (arr, data) => {
      let form = JSON.parse(JSON.stringify(data));
      arr.map((d) => {
        form[d] = proxy.$defined.format(form[d]);
      });
      if (Array.isArray(form.manuscript)) {
        let urls = [];
        form.manuscript.map((item) => {
          urls.push(item.url);
        });
        form.manuscript = urls.join(",");
      }
      form.id = route.query.id;
      return form;
    };

    const handleSelectionChange = (val, key) => {
      // 选中电梯数据
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state[key] = elevatorIds;
    };

    const checkSelectEle = (key) => {
      if (state[key].length > 0) {
        return state[key].join(",");
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
        return "";
      }
    };

    const exportFinance = async () => {
      // 导出收款信息 选中电梯
      var ids = checkSelectEle("selectEleData");
      if (ids !== "") {
        let res = await proxy.$api.maintenance.exportFinance({
          eleIds: ids,
          upId: route.query.id,
        });
        proxy.$defined.exportFunc(res);
      }
    };

    const dialogOnEvent = (dialog, bool) => {
      if (bool === true || bool === "true") {
        if (checkSelectEle("selectEleData") !== "") {
          state[dialog] = bool;
        }
      } else {
        state[dialog] = bool;
      }
    };

    const addNewElevator = async () => {
      // 新增电梯到合同
      var ids = checkSelectEle("selectNewData");
      if (ids !== "") {
        await proxy.$api.maintenance.addContractEle(route.query.id, {
          elevatorStr: ids,
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newElevatorVisible = false;
        getElevatorList(state.paging);
      }
    };

    const setValueByRank = (val, key) => {
      // 计算合同时间段
      state[key] = val;
      if (state.beginDate && state.endDate) {
        let sTime = Date.parse(new Date(state.beginDate));
        let eTime = Date.parse(new Date(state.endDate));
        let days = Math.floor((eTime - sTime) / (24 * 3600 * 1000));
        state.basicColumns.map((item) => {
          if (item.prop === "durationMonth") {
            item.value = parseInt(days / 30);
          } else if (item.prop === "duration") {
            item.value = parseInt(days / 365);
          } else if (item.prop === key) {
            item.value = val;
          }
        });
      }
    };

    init();

    return {
      ...toRefs(state),
      onEditSubmit,
      setIsEdit,
      remotePersonMethod,
      getBillData,
      getElevatorList,
      getBillList,
      getReceiptList,
      showReceiptDetail,
      deleteReceiptDetail,
      deleteElevator,
      editReceiptDetail,
      exportFinance,
      handleSelectionChange,
      addBilling,
      addReceipt,
      dialogOnEvent,
      getAddEleList,
      addNewElevator,
      getRenewList,
      setValueByRank,
    };
  },
};
</script>

<style lang="scss">
#upkeepContracts {
  .form-button,
  .form-button .el-form-item__content {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #upkeepContracts {
    margin-top: -20px;
  }
}
</style>
